<template>
  <v-container>
    <validation-observer v-slot="{ invalid }">
      <v-row align="center" justify="center" class="mt-3">
        <base-heading
          class="text-center"
          text="Reset password"
          sub="we'll send you an email with a reset link shortly"
        />
      </v-row>
      <v-row align="center" justify="center">
        <v-chip class="mb-3" outlined @click="$emit('prev')">
          <v-icon color="primary" left>mdi-account-circle</v-icon>
          <span class="body-2" v-text="email" />
          <v-icon color="black" right>mdi-chevron-down</v-icon>
        </v-chip>
        <v-text-field v-model="email" class="d-none" />
      </v-row>

      <base-verification-code :length="6" justify="center" @input="updateCode"></base-verification-code>

      <v-row>
        <v-col>
          <validation-provider :name="$t('password')" rules="required" vid="originalPassword" v-slot="{ errors }">
            <v-text-field
              v-model="password"
              :error-messages="errors"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              class="mb-3"
              :label="$t('password') + '*'"
              name="password"
              @click:append="showPassword = !showPassword"
              autocomplete="new-password"
            />
          </validation-provider>
        </v-col>

        <v-col>
          <validation-provider
            :name="$t('passwordConfirmation')"
            rules="required|confirmed:@originalPassword"
            v-slot="{ errors }"
            vid="passwordConfirm"
          >
            <v-text-field
              v-model="passwordConfirmation"
              :error-messages="errors"
              :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmation ? 'text' : 'password'"
              class="mb-3"
              :label="$t('passwordConfirmation') + '*'"
              name="password"
              @click:append="showConfirmation = !showConfirmation"
              autocomplete="new-password"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-8" justify="center">
        <v-btn
          :loading="isLoading"
          :disabled="(!code && !email) || invalid"
          class="text-capitalize"
          color="primary"
          depressed
          @click="submit"
        >
          {{ $t("resetPassword") }}
        </v-btn>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default Vue.extend({
  data: () => ({
    code: null,
    password: null,
    showPassword: null,
    passwordConfirmation: null,
    showConfirmation: null,
  }),
  computed: {
    ...mapState(["email", "isLoading"]),
  },

  methods: {
    ...mapActions("cognito", ["changePassword"]),
    ...mapMutations(["setEmail", "setPassword", "setIsLoading", "setSnackbar"]),
    async submit() {
      this.hasError = false;
      this.setIsLoading(true);
      try {
        console.log("changing password");
        await this.changePassword({
          username: this.email,
          code: this.code,
          newPassword: this.password,
        });

        this.setSnackbar({
          type: "success",
          msg: `${this.$t("signinSuccess")} ${this.email}`,
        });
        setTimeout(() => {
          this.$router.push("/");
        }, 2000);
      } catch (err) {
        console.log(err);
        this.setSnackbar({
          type: "error",
          msg: err.message,
        });
      }
      this.setIsLoading(false);
      this.$emit("next");
    },
    updateCode(code) {
      this.code = code;
    },
  },
});
</script>

<style></style>
